import React, { useState } from "react";
import { Modal, Button, Form, Alert, Row, Col} from "react-bootstrap";
import axios from "axios";

const ForgotPassword = ({ show, onHide }) => {
  
    const [activeTab, setActiveTab] = useState("discord");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    const [formData, setFormData] = useState({
        identifiant:"",
        discordId: "",
        email: "",
      });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
      };

      const handleSendPassword = async () => {
        console.log("Demande de reset password en cours");
        try {
          const response = await axios.post(
            "https://api.bounn.fr/users/resetpassword",
            formData,
            {
              withCredentials: true,
              credentials: "include",
            }
          );
          if (response.data.success) {
            console.log("Demande de réinitialisation effectuée");
            setSuccess(true);
          } else {
            setErrors({
              serverError: response.data.message,
            });
          }
        } catch (error) {
          setErrors({
            serverError: error.response?.data?.message || "Une erreur s'est produite lors de la communication avec le serveur",
          });
        }
      }
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Réinitialiser votre mot de passe</Modal.Title>
        </Modal.Header>
        {success ? (
          <>
          <Row>
          <div className="success">
          <p>✅ Un lien vous a été envoyé pour réinitialiser votre mot de passe !</p>
          </div>
          </Row>
          </>
        ) : (
          <>

        <Modal.Body>
        <Form>
            <Form.Group controlId="formName">
              <Form.Label>Indiquez votre nom d'utilisateur :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre identifiant"
                name="identifiant"
                value={formData.identifiant || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
          <hr></hr>
          DESCRIPTION SELECTIONNER METHODE
          <div className="resetPassword-choix">
                  <Button
            className={`fork-btn-inner ${activeTab === "discord" ? "active" : ""}`}
            onClick={() => handleTabClick("discord")}
          >
            Via discord
        </Button>
        <Button
            className={`fork-btn-inner ${activeTab === "email" ? "active" : ""}`}
            onClick={() => handleTabClick("email")}
          >
            Par Email
        </Button>
        </div>

          <hr></hr>
          {activeTab === "discord" && 
          <>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Votre ID discord :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre ID"
                name="discordId"
                value={formData.discordId || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
          </>
          }
          {activeTab === "email" && 
          <>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Votre adresse email :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre email"
                name="email"
                value={formData.email || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
          </>
          }

          {errors.serverError && (
            <>
              <br />
              <Alert variant="danger">{errors.serverError}</Alert>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Annuler
          </Button>
            <Button variant="primary" onClick={handleSendPassword}>
             Réinitialiser mon mot de passe
            </Button>
        </Modal.Footer>
        </>
          )}
      </Modal>
    </>
  );
};

export default ForgotPassword;
