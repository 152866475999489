import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Button, FormControl } from "react-bootstrap";

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Empêche le comportement par défaut du formulaire (s'il y en a un)
      handleResetPassword(); // Appelle la fonction de gestion de la soumission du formulaire
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas !");
    }
    try {
      const response = await axios.post(
        "https://api.bounn.fr/users/resetpassword",
        { resetToken, newPassword },
        { withCredentials: true }
      );

      if (response.data.success) {
        setSuccess(true);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Une erreur s'est produite lors de la réinitialisation du mot de passe."
      );
    }
  };

  return (
    <>
      <Container fluid className="dashboard-section">
        <h1 className="dashboard-heading">
          Réinitialisation de votre{" "}
          <strong className="main-name">mot de passe</strong>
        </h1>
        <hr></hr>
        <div className="login-container">
          <div className="login-card js-login-card">
            <div className="login-card__cnt js-login-cnt">
              {success ? (
                <>
                <Row>
                <div className="success">
                <p>✅ Mot de passe réinitialisé avec succès!</p>
                </div>
                </Row>
                </>
              ) : (
                <>
                  <div className="login-card__name">Nouveau mot de passe :</div>
                  <div className="login-card__txt">
                    <Row>
                      <Col>
                        <FormControl
                          type="password"
                          placeholder="Nouveau mot de passe"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="login-card__name">
                    Retapez le mot de passe :
                  </div>
                  <div className="login-card__txt">
                    <Row>
                      <Col>
                        <FormControl
                          type="password"
                          placeholder="Répétez votre mot de passe"
                          value={confirmNewPassword}
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </Col>
                    </Row>
                  </div>
                  <Row className="mx-auto">
                    <Button
                      className="fork-btn-inner login-button"
                      onClick={handleResetPassword}
                      onKeyPress={handleKeyPress}
                    >
                      VALIDER
                    </Button>
                  </Row>
                </>
              )}
              {errorMessage && (
                <Row className="error-message">
                  <Col>{errorMessage}</Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ResetPassword;
