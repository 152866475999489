import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../assets/img/Homeimg.png";
import Typewriter from "typewriter-effect";

import { CgCPlusPlus } from "react-icons/cg";
import {
  DiNodejsSmall,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiCss3,
  DiHtml5,
  DiVisualstudio,
  DiUnitySmall,
  DiLinux,
} from "react-icons/di";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                <span className="home-slogan">Bienvenue !</span>{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>
              <h1 className="heading-slogan">
                REJOIGNEZ NOTRE{" "}
                <strong className="main-name">COMMUNAUTÉ</strong> DE CRÉATEURS !
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Typewriter
                  options={{
                    strings: [
                      "Trouvez l'inspiration.",
                      "Rencontrez des passionés..",
                      ".. ou apprenez de nouveaux skills !",
                      "Developpez vos talents !",
                      "Elargissez votre réseau..",
                      "Trouvez des conseils !",
                      "Découvrez de nouveaux projets.",
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 30,
                    delay: 50,
                  }}
                />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
            <hr></hr>
          </Row>
        </Container>

        <Container>
          <h1 className="heading-slogan">
            NOS OUTILS <strong className="main-name">UTILISÉS</strong>{" "}
          </h1>

          <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={4} md={2} className="tech-icons">
              <DiNodejs />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiReact />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiMongodb />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <CgCPlusPlus />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiUnitySmall />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiNodejsSmall />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiHtml5 />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiVisualstudio />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiCss3 />
            </Col>
            <Col xs={4} md={2} className="tech-icons">
              <DiLinux />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
