import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../assets/img/NavLogo.png";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineAppstoreAdd,
  AiOutlineHome,
  AiOutlineTeam,
  AiOutlineUser,
  AiOutlineLogout,
} from "react-icons/ai";
import { useUser } from "./AppContext";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { user, logout, setConnected} = useUser();
  const navigate = useNavigate()

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const handleLogout = () => {
  // Définir la date d'expiration du cookie à une date antérieure à la date actuelle
  const expirationDate = new Date("Thu, 01 Jan 1970 00:00:00 UTC");

  // Effacer le cookie JWT en définissant une date d'expiration passée
  document.cookie = `jwt=; path=/; expires=${expirationDate.toUTCString()}`;

  // Mettre à jour l'état local et déconnecter l'utilisateur
  updateExpanded(false);
  logout();
  setConnected(false); 
  // Rediriger l'utilisateur ou effectuer d'autres opérations de déconnexion nécessaires
  navigate('/');
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img
            src={logo}
            className="img-fluid logo"
            width="50"
            height="50"
            alt="brand"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "3px", margin: "2px" }} />
                Accueil
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/projets"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineAppstoreAdd
                  style={{ marginBottom: "3px", margin: "2px" }}
                />
                Projets
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/members"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineTeam style={{ marginBottom: "3px", margin: "2px" }} />
                Membres
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link as={Link} to="/services" onClick={() => updateExpanded(false)}>
                <AiOutlineCoffee style={{ marginBottom: "5px", margin: "2px" }}/>
                Services
              </Nav.Link>
            </Nav.Item> */}

            {user ? (
              <>
                <Nav.Item className="btn">
                  <Button as={Link} to="/dashboard" className="fork-btn-inner">
                    <AiOutlineUser
                      style={{ fontSize: "1.2em", margin: "3px" }}
                    />
                    Dashboard
                  </Button>
                </Nav.Item>
                <Nav.Item className="btn">
                  <Button as={Link} to="/dashboard" className="fork-btn-inner" onClick={handleLogout}>
                    <AiOutlineLogout
                      style={{ fontSize: "1.2em", margin: "3px" }}
                    />
                    Logout
                  </Button>
                </Nav.Item>
              </>
            ) : (
              <Nav.Item className="btn">
                {/* Si l'utilisateur n'est pas connecté, affichez le bouton de connexion */}
                <Button
                  as={Link}
                  to="/dashboard"
                  className="fork-btn-inner"
                >
                  <AiOutlineUser style={{ fontSize: "1.2em", margin: "3px" }} />
                  Login
                </Button>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
