import React, {useState} from "react";
import { Container, Button } from "react-bootstrap";
import Login from "./Login";
import Register from "./Register";
import AccountDashboard from "./AccountDashboard";
import { useUser } from "../AppContext";

const DashboardPage = (props) => {
  
  const [activeTab, setActiveTab] = useState("Login");
  const { user } = useUser();

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  return (
    <section>
      <Container fluid className="dashboard-section">
        <h1 className="dashboard-heading">
        Gestion de <strong className="main-name">votre compte</strong>
        </h1>
        <hr></hr>


        {user ? (
        // USER CONNECTE
        <AccountDashboard/>
      ) : (
        // USER NON CONNECTE
        <>
          <div className="dashboard-choix">
            <Button
              className={`fork-btn-inner ${activeTab === "Login" ? "active" : ""}`}
              onClick={() => handleTabClick("Login")}
            >
              Connexion
            </Button>
            <Button
              className={`fork-btn-inner ${activeTab === "Register" ? "active" : ""}`}
              onClick={() => handleTabClick("Register")}
            >
              Inscription
            </Button>
          </div>

          {activeTab === "Login" && <Login />}
          {activeTab === "Register" && <Register />}
        </>
      )}
      </Container>
    </section>
  );
};

export default DashboardPage;
