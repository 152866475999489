import React, { useState } from "react";
import { Modal, Button, Form, Alert, Row} from "react-bootstrap";
import axios from "axios";

const LinkDiscordModal = ({ show, onHide }) => {
  const [formData, setFormData] = useState({
    currentDiscord: "",
    currentCode: "",
  });
  const [errors, setErrors] = useState({});
  const [waitForCode, setWaitForCode] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Effacez l'erreur associée à ce champ lorsqu'il est modifié
    setErrors({ ...errors, [name]: "" });
  };

  const handleLaunchMP = async () => {
    try {
      if (!formData.currentDiscord) {
        setErrors({
          ...errors,
          serverError: "Veuillez indiquer un pseudo",
        });
        return;
      }
      const response = await axios.post(
        "https://api.bounn.fr/discord/linkmp",
        formData,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      if (response.data.success) {
        setWaitForCode(true);
      } else {
        setErrors({
          serverError: response.data.message,
        });
      }
    } catch (error) {
      setErrors({
        serverError: error.response?.data?.message || "Une erreur s'est produite lors de la communication avec le serveur",
      });
    }
  };

  const handleValidCode = async () => {
    console.log("Validation en cours");
    try {
      if (!formData.currentCode) {
        setErrors({
          ...errors,
          serverError: "Veuillez indiquer le code reçu dans vos MP discord",
        });
        return;
      }
      const response = await axios.post(
        "https://api.bounn.fr/discord/linkmp",
        formData,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      if (response.data.success) {
        setWaitForCode(true);
        setSuccess(true);
      } else {
        setErrors({
          serverError: response.data.message,
        });
      }
    } catch (error) {
      setErrors({
        serverError: error.response?.data?.message || "Une erreur s'est produite lors de la communication avec le serveur",
      });
    }
  };

  return (
    <>
      TEST
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Lier votre compte discord</Modal.Title>
        </Modal.Header>
        {success ? (
          <>
                          <Row>
                <div className="success">
                <p>✅ Discord lié avec succès!</p>
                </div>
                </Row>
          </>
        ) : (
          <>
        <Modal.Body>
          Pour lier votre compte Bounn, vous devez avoir rejoind l'un de nos serveurs partenaires. 
          <br></br>Pour vérifier cela, assurez vous que le <strong>Bounn bot</strong> existe dans votre serveur. Si non, rejoignez notre discord privé pour poursuivre la procédure en cliquant sur le lien suivant : 
          <br></br>
          <br></br><a
          href="https://discord.gg/d2WYSVAsRv"
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          target="_blank"
          rel='noreferrer'
        >
          Rejoindre le discord Bounn
        </a>

          <hr></hr>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Mon ID Discord :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre ID"
                name="currentDiscord"
                value={formData.currentDiscord || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
          {waitForCode && (
            <>
              <hr></hr>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Votre code de validation :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez le code reçu"
                    name="currentCode"
                    value={formData.currentCode || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </>
          )}
          {errors.serverError && (
            <>
              <br />
              <Alert variant="danger">{errors.serverError}</Alert>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fermer
          </Button>
          {!waitForCode ? (
            // ETAPE 1
            <Button variant="primary" onClick={handleLaunchMP}>
              Obtenir mon code
            </Button>
          ) : (
            // ETAPE 2
            <Button variant="primary" onClick={handleValidCode}>
              Valider le code
            </Button>
          )}
        </Modal.Footer>
          </>
        ) }
      </Modal>
    </>
  );
};

export default LinkDiscordModal;
