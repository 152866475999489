import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useUser } from "./components/AppContext";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Licibid from "./components/Immobilier/Licibid";
import Dashboard from "./components/Account/Dashboard";
import Members from "./components/Creators/Members";
import Projets from "./components/Projets/Projets";
import Logs from "./components/Logs/Logs";
import ResetPassword from "./components/Account/ResetPassword";
// import Services from "./components/Services/Services";
// import Dashboard from "./components/Dashboard/Dashboard";
// import Projets from "./components/Projets/Projets";

function App() {
  const [load, upadateLoad] = useState(true);
    const {user, login, setUserData, logout, connected } = useUser();

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 2400);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
          console.log("App.js : Requête en cours");
            try {
                if(connected) {

                  
                  // Effectuer une requête GET pour obtenir les informations de l'utilisateur
                  const response = await fetch(`https://api.bounn.fr/users/dashboard`, {
                    withCredentials: true,
                    credentials: "include",
                  });
                  
                if (response.ok) {
                  // Si la requête est réussie, mettez à jour le contexte utilisateur avec les informations de l'utilisateur
                    const data = await response.json();

                    if (JSON.stringify(data.dashboardData) !== JSON.stringify(user)) {
                      login(data.dashboardData.user);
                      // Set user data in the context
                      setUserData(data.dashboardData);
                    }
                  }
                }

                  
                } catch (error) {
                  console.error("Erreur lors de la récupération des données de l'utilisateur :", error);
                }
            };
            
            // Appeler la fonction de récupération des données de l'utilisateur au chargement initial de l'application
            fetchUserData();
          }, [login, setUserData, user, logout, connected]);
          
  return (
    <BrowserRouter>
      <div className={load ? "preloader-accueil" : "preloader-none"}></div>
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/licibid" element={<Licibid />} />
          <Route exact path="/members" element={<Members />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/projets" element={<Projets />} />
          <Route exact path="/logs" element={<Logs />} />
          <Route exact path="/resetpassword/:resetToken" element={<ResetPassword/>}/>

          {/* <Route exact path="/projets" element={<Projets />} />
             <Route exact path="/services" element={<Services />} />
<Route exact path="/dashboard" element={<Dashboard />} />* */}
        </Routes>
      </div>
      {/* </UserProvider> */}
    </BrowserRouter>
  );
}

export default App;
