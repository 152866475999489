import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";

const Licibid1 = (props) => {
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [libre, setLibre] = useState(true);
  const [cave, setCave] = useState(false);
  const [parking, setParking] = useState(false);
  const [surfaceMin, setSurfaceMin] = useState("");

  const [loadresult, updateLoadresult] = useState(false);
  const [resultStatut, updateResultStatut] = useState(
    "Connexion au serveur en cours.."
  );
  const [nbAnnonceIncomplete, setnbAnnonceIncomplete] = useState(0);
  const [resultatFinal, setResultatFinal] = useState("");

  // OBTENTION DES REGIONS DISPONIBLES (FETCH)
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        console.log("Connexion à la base de donnée + récupération des régions");
        const response = await fetch(`https://api.bounn.fr/regions`);
        const data = await response.json();
        console.log("Regions : " + data);
        updateResultStatut("En attente d'une selection de région dans le menu déroulant..")
        setRegions(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des régions:",
          error.message
        );
      }
    };

    fetchRegions();
  }, []);

  // OBTENTION DES VILLES DISPONIBLES (FETCH)
  useEffect(() => {
    const fetchCities = async () => {
      if (selectedRegion) {
        try {
          console.log("Recherche des villes pour la région :", selectedRegion);
          const response = await fetch(
            `https://api.bounn.fr/cities/${selectedRegion}`
          );
          const data = await response.json();
          setCities(data);
          updateResultStatut(`Selectionnez la ville pour ${selectedRegion}`);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des villes:",
            error.message
          );
        }
      }
    };

    fetchCities();
  }, [selectedRegion]);

  // GESTION PARAMETRES CHECKBOX
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    // Mettez à jour l'état des options de checkbox
    switch (name) {
      case "libre":
        setLibre(checked);
        break;
      case "cave":
        setCave(checked);
        break;
      case "parking":
        setParking(checked);
        break;
      default:
        break;
    }
  };

  // GESTION REQUETE FINALE
  const fetchData = async () => {
    try {
      // Construisez votre objet de critères de recherche en fonction des paramètres reçus
      const searchCriteria = { ville: selectedCity };
      if (libre) {
        searchCriteria.libre = true;
      }
      if (cave) {
        searchCriteria.cave = true;
      }
      if (parking) {
        searchCriteria.parking = true;
      }
      if (surfaceMin) {
        searchCriteria.surfaceMin = surfaceMin;
      }
      const response = await fetch(
        `https://api.bounn.fr/annonces?${new URLSearchParams(searchCriteria)}`
      );
      const data = await response.json();
      setResultatFinal(data);
      if (selectedRegion && selectedCity) {
        updateLoadresult(true);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des annonces:",
        error.message
      );
    }
  };
  const handleSearchButtonClick = () => {
    fetchData();
  };

  // TRAITEMENT DES DONNES RECUES :
  const [nbAnnonces, setnbAnnonces] = useState(0);
  const [dateOldAnnonce, setdateOldAnnonce] = useState("");
  const [prixMoyen, setprixMoyen] = useState(0);
  const [surfaceMoyen, setsurfaceMoyen] = useState(0);
  const [prixMoyenm2, setprixMoyenm2] = useState(0);
  const [prixMini, setprixMini] = useState(0);
  const [prixMaxi, setprixMaxi] = useState(0);
  const [ecartMoyen, setecartMoyen] = useState(0);

  useEffect(() => {
    // Calcul nb annonce
    const annoncesCount = resultatFinal.length;
    setnbAnnonces(annoncesCount);

    // obtention date ancienne
    if (annoncesCount > 0) {
      const oldestDate = resultatFinal.reduce(
        (minDate, annonce) =>
          new Date(annonce.date) < new Date(minDate) ? annonce.date : minDate,
        resultatFinal[0].date
      );
      const formattedDate = new Date(oldestDate).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      setdateOldAnnonce(formattedDate);
    }

    if (resultatFinal.length > 0) {
      // MOYENNE DES PRIX D'ADJUDICATION
      // Convertir en nombres
      const prixAdjucationNumbers = resultatFinal.map((annonce) =>
        parseFloat(annonce.prixAdjucation.replace(/\s/g, ""))
      );
      // Calcul de la moyenne des prixAdjudication
      const totalPrixAdjucation = prixAdjucationNumbers.reduce(
        (total, prix) => total + prix,
        0
      );
      const moyennePrixAdjucation = totalPrixAdjucation / resultatFinal.length;
      const MoyennePrixAdjuArrondi = Math.ceil(moyennePrixAdjucation);
      setprixMoyen(MoyennePrixAdjuArrondi);

      // MOYENNE SURFACE :
      const annoncesAvecSurface = resultatFinal.filter(
        (annonce) => annonce.surface !== undefined
      );

      // Convertir en nombres
      const surfaceNumbers = annoncesAvecSurface.map((annonce) =>
        parseFloat(annonce.surface.replace(/\s/g, ""))
      );
      // Calcul de la moyenne des prixAdjudication
      const totalSurface = surfaceNumbers.reduce(
        (total, prix) => total + prix,
        0
      );
      const moyenneSurface = totalSurface / annoncesAvecSurface.length;
      const moyenneSurfaceArrondie = Math.ceil(moyenneSurface);
      setsurfaceMoyen(moyenneSurfaceArrondie);

      // MOYENNE €/m²
      const prixMoyenMetre = Math.ceil(prixMoyen / moyenneSurfaceArrondie);
      setprixMoyenm2(prixMoyenMetre);

      // PRIX m² ADJUDICATION MINIMUM & MAXIMUM
      // Convertir les prix d'adjudication en nombres
      const prixAdjudicationNumbers = resultatFinal.map((annonce) =>
        parseFloat(annonce.prixAdjucation.replace(/\s/g, ""))
      );

      // Trouver le prix d'adjudication minimum
      const prixMin = Math.min(...prixAdjudicationNumbers);
      // Trouver l'annonce correspondant au prix minimum
      const annoncePrixMin = resultatFinal.find(
        (annonce) =>
          parseFloat(annonce.prixAdjucation.replace(/\s/g, "")) === prixMin
      );
      // Calculer le prix minimum par mètre carré
      const prixMinParM2 = Math.ceil(prixMin / annoncePrixMin.surface);
      setprixMini(prixMinParM2);

      // Prix d'adjudication maximum
      // Trouver le prix d'adjudication minimum
      const prixMax = Math.max(...prixAdjudicationNumbers);
      // Trouver l'annonce correspondant au prix minimum
      const annoncePrixMax = resultatFinal.find(
        (annonce) =>
          parseFloat(annonce.prixAdjucation.replace(/\s/g, "")) === prixMax
      );
      // Calculer le prix minimum par mètre carré
      const prixMaxParM2 = Math.ceil(prixMax / annoncePrixMax.surface);
      setprixMaxi(prixMaxParM2);

      // Ecart pourcentage
      const ecartsPercentages = resultatFinal.map((annonce) => {
        const prixDepart = parseFloat(annonce.prixDepart.replace(/\s/g, ""));
        const prixAdjudication = parseFloat(
          annonce.prixAdjucation.replace(/\s/g, "")
        );

        // Calculer l'écart en pourcentage
        const ecartPourcentage =
          ((prixAdjudication - prixDepart) / prixDepart) * 100;
        return ecartPourcentage;
      });
      // Calculer la moyenne des écarts en pourcentage
      const ecartMoyenPourcentage = Number(
        (
          ecartsPercentages.reduce((acc, ecart) => acc + ecart, 0) /
          ecartsPercentages.length
        ).toFixed(2)
      );

      // Mise à jour de l'état
      setecartMoyen(ecartMoyenPourcentage);
    }
  }, [resultatFinal, prixMoyen]);

  return (
    <Container>
      <Row>
        {/* PARTIE RECHERCHE */}
        <Col md={4} xl={3}>
          <div className="licitor-container">
            <div className="licitor-card js-licitor-card">
              <div className="licitor-card__cnt js-licitor-cnt">
                <div className="licitor-card__name">
                  Paramètres de recherche :
                </div>
                <div className="licitor-card__txt">
                  <div className="licitor-card__title">
                    <strong>Localisation :</strong>
                  </div>
                  <select
                    value={selectedRegion}
                    onChange={(e) => {
                      const newRegion = e.target.value;
                      if (newRegion !== selectedRegion) {
                        setSelectedRegion(newRegion);
                      }
                    }}
                  >
                    <option disabled selected value="0">
                      Sélectionnez une région
                    </option>
                    {Array.isArray(regions) &&
                      regions.map((region, index) => (
                        <option key={index} value={region}>
                          {region}
                        </option>
                      ))}
                  </select>

                  <select
                    value={selectedCity}
                    onChange={(e) => {
                      const selectedCityValue = e.target.value;
                      setSelectedCity(selectedCityValue);
                      updateResultStatut(
                        "Sélection de critères supplémentaires..."
                      );
                      const selectedCityObject = cities.find(
                        (city) => city.ville === selectedCityValue
                      );
                      // Vérification si la ville est trouvée
                      if (selectedCityObject) {
                        setnbAnnonceIncomplete(selectedCityObject.resultCount);
                      } else {
                        setnbAnnonceIncomplete(0); // Ou une valeur par défaut
                      }
                    }}
                  >
                    <option disabled selected value="">
                      Choisir une ville
                    </option>
                    {cities.map((city, index) => (
                      <option key={index} value={city.ville}>
                        {`${city.ville} (${city.resultCount} annonces)`}
                      </option>
                    ))}
                  </select>

                  {/* CHECKBOX */}
                  <Row className="d-flex align-items-center">
                    <Col xs={6}>
                      <strong>Surface mini :</strong>
                    </Col>
                    <Col xs={6}>
                      <input
                        type="number"
                        placeholder="m²"
                        className="licitor-card__surface"
                        value={surfaceMin}
                        onChange={(e) => setSurfaceMin(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <strong>Libre :</strong>
                      <br></br>
                    </Col>
                    <input
                      type="checkbox"
                      name="libre"
                      checked={libre}
                      onChange={handleCheckboxChange}
                    />
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <strong>Cave :</strong>
                      <br></br>
                    </Col>
                    <input
                      type="checkbox"
                      name="cave"
                      checked={cave}
                      onChange={handleCheckboxChange}
                    />
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <strong>Parking :</strong>
                      <br></br>
                    </Col>
                    <input
                      type="checkbox"
                      name="parking"
                      checked={parking}
                      onChange={handleCheckboxChange}
                    />
                  </Row>
                  <Row>
                    <Button
                      className="fork-btn-inner"
                      onClick={handleSearchButtonClick}
                    >
                      RECHERCHER
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>

        {/* PARTIE RESULTAT */}
        <Col md={8} xl={9}>
          <div className="licitor-container">
            <div className="licitor-card js-licitor-card">
              <div className="licitor-card__cnt js-licitor-cnt">
                {!loadresult ? (
                  <>
                    <div className="licitor-card__result">
                      <p>{resultStatut}</p>
                      <div className="preloader-licitor"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="licitor-card__name">
                      VILLE CHOISIE : {selectedCity}
                    </div>
                    <div className="licitor-card__txt">
                      <div className="licitor-card__title">
                        <strong>Nombre d'annonces analysées :</strong>{" "}
                        {nbAnnonces}
                      </div>
                      <div className="licitor-card__title">
                        <strong>Nombre d'annonces incomplètes :</strong>{" "}
                        {nbAnnonceIncomplete - nbAnnonces}
                      </div>
                      <div className="licitor-card__title">
                        <strong>Date de la plus vieille annonce :</strong>{" "}
                        {dateOldAnnonce}
                      </div>
                      <hr></hr>
                      <div className="licitor-card__title">
                        <strong>Prix moyen : </strong> {prixMoyen} €
                      </div>
                      <div className="licitor-card__title">
                        <strong>Surface moyenne : </strong> {surfaceMoyen} m²
                      </div>
                      <div className="licitor-card__title">
                        <strong>Prix moyen au m² : </strong> {prixMoyenm2} €/m²
                      </div>
                      <div className="licitor-card__title">
                        <strong>Prix minimum d'adjudication au m² : </strong>{" "}
                        {prixMini} €/m²
                      </div>
                      <div className="licitor-card__title">
                        <strong>Prix maximum d'adjudication au m² : </strong>{" "}
                        {prixMaxi} €/m²
                      </div>
                      <hr></hr>
                      <div className="licitor-card__title">
                        <strong>
                          Ecart moyen mise en vente/adjudication :
                        </strong>
                        {" + " + ecartMoyen} %
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Licibid1;
