// EditProfileModal.js
import React, { useState } from "react";
import { Modal, Button, Form, Alert, Row } from "react-bootstrap";
import axios from "axios";
import { useUser } from "../AppContext";

const MAX_FILE_SIZE_MB = 5; // Définissez la taille maximale du fichier en mégaoctets
const ALLOWED_FILE_TYPES = ["image/png", "image/jpeg"];

const EditProfileModal = ({ show, onHide }) => {
  const { logout } = useUser();

  const [formData, setFormData] = useState({
    currentPassword: "",
    selectedOption: "",
    newPassword: "",
    newPasswordConfirm: "",
    website: "",
    email: "",
    pseudo: "",
    avatar: null,
    avatarPreview: null,
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Effacez l'erreur associée à ce champ lorsqu'il est modifié
    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Vérifiez la taille du fichier
    if (file && file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      // Affichez un message d'erreur (vous pouvez également gérer cela avec un état d'erreur)
      setErrors({
        ...errors,
        fileError: `La taille du fichier ne doit pas dépasser ${MAX_FILE_SIZE_MB} Mo.`,
      });
      return;
    }
    // Vérifiez si le fichier existe et est du bon type
    if (file && ALLOWED_FILE_TYPES.includes(file.type)) {
      // Mettez à jour l'état avec le fichier sélectionné et sa prévisualisation
      setFormData({
        ...formData,
        avatar: file,
        avatarPreview: URL.createObjectURL(file),
      });

      // Effacez l'erreur associée au type de fichier lorsque le fichier est modifié
      setErrors({ ...errors, fileError: "" });
    } else {
      // Affichez un message d'erreur si le type de fichier n'est pas autorisé
      setErrors({
        ...errors,
        fileError: "Veuillez télécharger uniquement des fichiers PNG ou JPEG.",
      });
    }

    // Mettez à jour l'état avec le fichier sélectionné et sa prévisualisation
    setFormData({
      ...formData,
      avatar: file,
      avatarPreview: URL.createObjectURL(file),
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (!formData.selectedOption) {
        // Affichez un message d'erreur (vous pouvez également gérer cela avec un état d'erreur)
        setErrors({
          ...errors,
          serverError: "Veuillez selectionner une option.",
        });
        return;
      }

      const response = await axios.post(
        "https://api.bounn.fr/users/edit",
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.message);

      if (formData.selectedOption === "pseudo") {
        // Déconnectez l'utilisateur après une mise à jour réussie du nom d'utilisateur (pseudo)
        logout();
      }
      // Réinitialisation des champs
      setFormData((prevFormData) => ({
        ...prevFormData,
        currentPassword: "",
        selectedOption: "",
        newPassword: "",
        newPasswordConfirm: "",
        website: "",
        email: "",
        pseudo: "",
      }));
      setErrors({ ...errors, serverError: "" });
      setErrors({ ...errors, fileError: "" });
      setSuccess(true);
    } catch (error) {
      // Gérez les erreurs de la requête
      if (error.response && error.response.data) {
        // Si le serveur a renvoyé des données d'erreur
        setErrors({ ...errors, serverError: error.response.data.message });
      } else if (error.request) {
        // Si la requête a été faite mais aucune réponse n'a été reçue
        setErrors({ ...errors, serverError: "Aucune réponse du serveur." });
      } else {
        // Si une erreur s'est produite lors de la configuration de la requête
        setErrors({
          ...errors,
          serverError: "Erreur de connexion au serveur.",
        });
      }
    }
  };

  const handleModalClose = () => {
    // Réinitialiser formData
    setFormData({
      currentPassword: "",
      selectedOption: "",
      newPassword: "",
      newPasswordConfirm: "",
      website: "",
      email: "",
      pseudo: "",
      avatar: null,
      avatarPreview: null,
    });
    // Réinitialiser les erreurs
    setErrors({});
    // Réinitialiser le succès
    setSuccess(false);
    // Appeler onHide pour fermer la modal
    onHide();
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier vos informations</Modal.Title>
      </Modal.Header>
      {success ? (
        <>
          <Row>
            <div className="success">
              <p>✅ Informations modifiées avec succès!</p>
            </div>
          </Row>
        </>
      ) : (
        <>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Mot de passe actuel* :</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  name="currentPassword"
                  value={formData.currentPassword || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <hr></hr>
              <Form.Group controlId="formModificationType">
                <Form.Label>Choisissez le type de modification :</Form.Label>
                <Form.Control
                  as="select"
                  name="selectedOption"
                  value={formData.selectedOption || ""}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Sélectionnez une option
                  </option>
                  <option value="password">Modifier le mot de passe</option>
                  <option value="avatar">Modifier la photo de profil</option>
                  <option value="website">Changer le site web</option>
                  <option value="email">Changer l'adresse email</option>
                  <option value="pseudo">
                    Changer votre nom d'utilisateur
                  </option>
                </Form.Control>
              </Form.Group>
              <hr></hr>
              {formData.selectedOption === "password" && (
                <Form.Group controlId="formNewPassword">
                  <Form.Label>Nouveau mot de passe :</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Entrez votre nouveau mot de passe"
                    name="newPassword"
                    value={formData.newPassword || ""}
                    onChange={handleInputChange}
                  />
                  <Form.Label>
                    <br />
                    Répétez le mot de passe :
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Répétez votre nouveau mot de passe"
                    name="newPasswordConfirm"
                    value={formData.newPasswordConfirm || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}
              {formData.selectedOption === "website" && (
                <Form.Group controlId="website">
                  <Form.Label>Lien de votre site web :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre nouvelle URL"
                    name="website"
                    value={formData.website || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}
              {formData.selectedOption === "email" && (
                <Form.Group controlId="email">
                  <Form.Label>Nouvelle adresse email :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre nouvel email"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}
              {formData.selectedOption === "pseudo" && (
                <Form.Group controlId="pseudo">
                  <Form.Label>Nouveau pseudo:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre nouveau pseudo"
                    name="pseudo"
                    value={formData.pseudo || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}
            </Form>
            {formData.selectedOption === "avatar" && (
              <Form.Group controlId="formAvatar">
                <Form.Label>Changer l'avatar :</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {/* {formData.avatarPreview && (
              <img
              src={formData.avatarPreview}
              alt="Avatar Preview"
              style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            )} */}
              </Form.Group>
            )}
            {errors.serverError && (
              <>
                <br />
                <Alert variant="danger">{errors.serverError}</Alert>
                <Alert variant="danger">{errors.fileError}</Alert>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Fermer
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Enregistrer les modifications
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default EditProfileModal;
