import React, { useState, useEffect } from "react";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [expandedLogIndex, setExpandedLogIndex] = useState(null);
  const [filters, setFilters] = useState({
    error: true,
    info: true,
    warning: true,
    user: "",
    IP: "",
    file: "",
  });

  // Ajout d'un état pour stocker le délai avant d'effectuer la requête
  const [searchTimeout, setSearchTimeout] = useState(null);

  const fetchLogs = async () => {
    try {
      const response = await fetch("https://api.bounn.fr/logs", {
        withCredentials: true,
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`Réponse du serveur non OK : ${response.statusText}`);
      }
      const data = await response.json();

      // Tri des logs du plus récent au plus ancien
      const sortedLogs = data.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      // Filtrage des logs en fonction des filtres
      const filteredLogs = sortedLogs.filter((log) => {
        const levelMatches = filters[log.level.toLowerCase()];

        // Filtrage par utilisateur seulement si le filtre d'utilisateur est spécifié
        const userMatches =
          !filters.user ||
          (log.user &&
            log.user.toLowerCase().includes(filters.user.toLowerCase()));

        // Filtrage par IP seulement si le filtre d'IP est spécifié
        const IPMatches =
          !filters.IP ||
          (log.IP && log.IP.toLowerCase().includes(filters.IP.toLowerCase()));

        return levelMatches && userMatches && IPMatches;
      });

      setLogs(filteredLogs);
    } catch (error) {
      console.error("Erreur lors de la récupération des logs :", error);
    }
  };

  useEffect(() => {
    // Utilisation du délai avant d'effectuer la requête
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Déclenchement de la requête après 500 ms d'inactivité de l'utilisateur
    const timeout = setTimeout(() => {
      fetchLogs();
    }, 500);

    setSearchTimeout(timeout);

    // Nettoyage du timeout lorsque le composant est démonté
    return () => clearTimeout(timeout);
  }, [filters]);

  const toggleExpandLog = (index) => {
    setExpandedLogIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleFilterChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const formatTimestamp = (timestamp) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    return new Date(timestamp).toLocaleString("fr-FR", options);
  };

  return (
    <Container className="logs-section">
      <h2>Visualisateur de logs</h2>
      <div className="logs-card js-logs-card">
        <Form>
          <Row className="d-flex align-items-center">
            <Col xs={12} md={4}>
              <Form.Group controlId="formErrorCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Error"
                  name="error"
                  checked={filters.error}
                  onChange={handleFilterChange}
                  className="mb-2"
                  style={{ marginRight: "15rem" }}
                />
              </Form.Group>
              <Form.Group controlId="formInfoCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Info"
                  name="info"
                  checked={filters.info}
                  onChange={handleFilterChange}
                  className="mb-2"
                  style={{ marginRight: "15rem" }}
                />
              </Form.Group>
              <Form.Group controlId="formWarningCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Warning"
                  name="warning"
                  checked={filters.warning}
                  onChange={handleFilterChange}
                  className="mb-2"
                  style={{ marginRight: "15rem" }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="formUserFilter">
                <Form.Label>User Filter</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  name="user"
                  value={filters.user}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="formIPFilter">
                <Form.Label>IP Filter</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IP"
                  name="IP"
                  value={filters.IP}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Horodatage</th>
            <th>Niveau</th>
            <th>File</th>
            <th>Utilisateur</th>
            <th>IP</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => toggleExpandLog(index)}
                style={{ cursor: "pointer" }}
              >
                <td>{formatTimestamp(log.timestamp)}</td>
                <td>{log.level}</td>
                <td>{log.file}</td>
                <td>{log.user}</td>
                <td>{log.IP}</td>
                <td>
                  {log.message.substring(0, 50)}
                  {log.message.length > 50 && "..."}
                </td>
              </tr>
              {expandedLogIndex === index && (
                <tr>
                  <td colSpan="5">
                    {log.message.length > 50 ? (
                      <>
                        {log.message}
                        <Button
                          variant="link"
                          onClick={() => toggleExpandLog(index)}
                        >
                          Réduire
                        </Button>
                      </>
                    ) : (
                      log.message
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Logs;
