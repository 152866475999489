import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
import { UserProvider } from "./components/AppContext";

const racine = ReactDOM.createRoot(document.getElementById("root"));

racine.render(
  //  <React.StrictMode> 
    <UserProvider>
      <App />
    </UserProvider>
  // </React.StrictMode> */}
);

reportWebVitals();