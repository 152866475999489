import React, {useState} from "react";
import { Container, Button } from "react-bootstrap";
import Licibid1 from './Licibid1';
import Licibid2 from './Licibid2';

const ImmoPage = (props) => {

    const [activeTab, setActiveTab] = useState("licibid1");

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  return (
    <section>
      <Container fluid className="immo-section">
        <h1 className="immo-heading">
          Bienvenue sur <strong className="main-name">Licibid. </strong>
        </h1>
        <p className="immo-sub-title">
          Version 1.0 : Utilisation du server et uniquement des données pré-enregistrées.<br/>
          Version 2.0 : Enregistrement des résultats dans le navigateur utilisateur et scraping en ligne.
        </p>

        <div className="immo-choix">
                  <Button
            className={`fork-btn-inner ${activeTab === "licibid1" ? "active" : ""}`}
            onClick={() => handleTabClick("licibid1")}
          >
            Licibid 1.0
        </Button>
        <Button
            className={`fork-btn-inner ${activeTab === "licibid2" ? "active" : ""}`}
            onClick={() => handleTabClick("licibid2")}
          >
            Licibid 2.0
        </Button>
        </div>

        {activeTab === "licibid1" && <Licibid1 />}
        {activeTab === "licibid2" && <Licibid2 />}
      </Container>
    </section>
  );
};

export default ImmoPage;
