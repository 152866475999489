import React, { useState } from "react";
import { Container, Col, Row, Button, FormControl, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// require("dotenv").config();

const RegisterPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [codeparrain, setCodeParrain] = useState("");
  const [role, setRole] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  const handleSigninButtonClick = async () => {
    try {
      // Vérification des champs
      if (
        !username ||
        !password ||
        !confirmPassword ||
        !email ||
        !codeparrain
      ) {
        setErrorMessage("Tous les champs sont obligatoires.");
        return;
      }

      // Vérification si le mot de passe correspond à la confirmation
      if (password !== confirmPassword) {
        setErrorMessage("La confirmation du mot de passe ne correspond pas.");
        return;
      }

      // Effectuer la requête POST vers le serveur
      const response = await fetch(`https://api.bounn.fr/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          confirmPassword,
          email,
          codeparrain,
          role,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Erreur lors de l'inscription.");
        return;
      }

      // Si la requête est réussie, récupérer le token depuis la réponse
      const data = await response.json();
      console.log("Utilisateur enregistré");
      setToken(data.token);
      setErrorMessage(""); // Réinitialiser le message d'erreur
      navigate("/");
    } catch (error) {
      console.error("Erreur lors de la requête d'inscription :", error);
      setErrorMessage("Erreur lors de la requête d'inscription.");
    }
  };

  return (
    <>
      <Container>
        <div className="login-container">
          <div className="login-card js-login-card">
            <div className="login-card__cnt js-login-cnt">
              Créez votre compte gratuitement <br></br>(*champs obligatoires)
              <div className="signin-card__name">Pseudo* :</div>
              <div className="signin-card__txt">
                <Row>
                  <Col>
                    <FormControl
                      type="text"
                      placeholder="Nom d'utilisateur"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="signin-card__name">Mot de passe* :</div>
              <div className="signin-card__txt">
                <Row>
                  <Col>
                    <FormControl
                      type="password"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="signin-card__name">
                Confirmation mot de passe* :
              </div>
              <div className="signin-card__txt">
                <Row>
                  <Col>
                    <FormControl
                      type="password"
                      placeholder="Mot de passe"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <Row>
                <div className="signin-card__name">Adresse e-mail* :</div>
                <div className="signin-card__txt">
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <div className="signin-card__name">Code parrain* :</div>
                <div className="signin-card__txt">
                  <Row>
                    <Col>
                      <FormControl
                        type="text"
                        placeholder="Code parrain"
                        value={codeparrain}
                        onChange={(e) => setCodeParrain(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row className="mx-auto">
                <div className="signin-card__name">Choisissez un rôle :</div>
                <div className="signin-card__txt">
                  <Row>
                    <Col>
                      <Form.Control
                        as="select"
                        placeholder="rôle"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="" disabled>
                          Sélectionnez un role
                        </option>
                        <option value="Gamer acharné">
                          Gamer acharné
                        </option>
                        <option value="Developpeur front/back">
                          Developpeur front/back
                        </option>
                        <option value="Bêta testeur">Bêta testeur</option>
                        <option value="Community Manager">Community Manager</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </div>
              </Row>
              <hr></hr>
              <Row className="mx-auto">
                <Button
                  className="fork-btn-inner register-button"
                  onClick={handleSigninButtonClick}
                >
                  S'INSCRIRE
                </Button>
              </Row>
              {errorMessage && (
                <Row className="error-message">
                  <Col>{errorMessage}</Col>
                </Row>
              )}
              {token && (
                <Row className="success-message">
                  <Col>Vous êtes inscrit! Token: {token}</Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RegisterPage;
