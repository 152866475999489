import React from "react";
import { Container, Row } from "react-bootstrap";
import EnDev from "../../assets/img/EnDev.png";

const EtudePage = (props) => {
  return (
    <Container>
      <p>En cours de developement.. </p>
    <img
            src={EnDev}
          />

  </Container>
  );
};

export default EtudePage;
