import React, { useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjetsCard from "./ProjetsCard";
// require("dotenv").config();

const Projets = () => {


  // const [projetData, setProjetData] = useState([]);
  const [statutFetch, setStatutFetch] = useState("");
  const [projets, setProjets] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatutFetch("Connexion à la base de donnée..");
        const response = await fetch(`https://api.bounn.fr/projets`);
        if (!response.ok) {
          setStatutFetch("Erreur lors de la connexion au serveur");
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        console.log(data);
        setProjets(data); // Notez l'utilisation de setProjets et l'emballage de data dans un tableau
        // setProjetData(data);
        setStatutFetch(null);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchData();
  }, []); // La dépendance vide signifie que cela s'exécute une seule fois après le montage du composant



  const list = projets.map((projet, i) => {
    return (
        <Row key={i}>
          <ProjetsCard 
          index={i}
          name={projet.name}
          date={projet.date}
          sousTitre={projet.sousTitre}
          description={projet.description}
          participer={projet.participer}
          stats1Name={projet.stats1Name}
          stats1={projet.stats1}
          stats2Name={projet.stats2Name}
          stats2={projet.stats2}
          stats3Name={projet.stats3Name}
          stats3={projet.stats3}
          button1={projet.button1}
          button1Link={projet.button1Link}
          button2={projet.button2}
          button2Link={projet.button2Link}
          button3={projet.button3}
          button3Link={projet.button3Link}
          />
        </Row>
    );
  });

  // return (
  // );

  return (
    
    <Container className="projet-section">
      <Container>
        <h1 className="projet-heading">
          Nos projets <strong className="main-name">en cours </strong>
        </h1>
        <p className="projet-sub-title">
          Participez à nos colaborations et obtenez vos premiers gains !
        </p>
<hr></hr>
        <Col className="projet-container">
          {list}
          </Col>
      </Container>
    </Container>
  );
};

export default Projets;
