// UserContext.js
import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    // Charger les données utilisateur depuis le stockage local lors du montage du composant
    const storedUser = localStorage.getItem("user");
    const storedConnected = localStorage.getItem("connected");
    const storedTimestamp = localStorage.getItem("timestamp");

    if (storedUser && storedConnected && storedTimestamp) {
      const currentTime = new Date().getTime();
      const expirationTime = Number(storedTimestamp) + 3600 * 1000; // 1 heure en millisecondes

      if (currentTime < expirationTime) {
        setUser(JSON.parse(storedUser));
        setConnected(JSON.parse(storedConnected));
      } else {
        // Si les données ont expiré, supprimer les données du localStorage
        localStorage.removeItem("user");
        localStorage.removeItem("connected");
        localStorage.removeItem("timestamp");
      }
    }
  }, []);

  const login = (userData) => {
    setUser(userData);
    // Enregistrer les données utilisateur dans le stockage local ou de session
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("connected", JSON.stringify(true));
    localStorage.setItem("timestamp", new Date().getTime().toString());
  };

  const setUserData = (userData) => {
    setUser(userData);
    setConnected(true);
    // Enregistrer les données utilisateur dans le stockage local ou de session
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("connected", JSON.stringify(true));
    localStorage.setItem("timestamp", new Date().getTime().toString());
  };

  const logout = () => {
    setUser(null);
    setConnected(false);
    // Supprimer les données utilisateur du stockage local ou de session lors de la déconnexion
    localStorage.removeItem("user");
    localStorage.removeItem("connected");
    localStorage.removeItem("timestamp");
  };

  console.log("User context value:", { user });

  return (
    <UserContext.Provider value={{ user, login, logout, setUserData, connected, setConnected }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
