import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import MembersCards from "./MembersCards";
// require("dotenv").config();

const MembersPage = (props) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetchMembers(); // Appel de la fonction pour récupérer les membres lors du montage du composant
  }, []);

  const fetchMembers = async () => {
    try {
      const response = await fetch(`https://api.bounn.fr/members`); // Remplacez par l'URL de votre backend
      const data = await response.json();
      setMembers(data);
      console.log(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des membres:", error);
    }
  };
  return (
    <section>
      <Container fluid className="profil-section">
        <h1 className="profil-heading">
          Notre communauté de <strong className="main-name">membres ! </strong>
        </h1>
        <p className="profil-sub-title">
          Contactez l'un de nos membres pour recevoir des conseils ou une
          prestation sur-mesure.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {members ? (
            <>
              {members.map((member) => (
                <Col
                  key={member._id}
                  md={4}
                  xl={3}
                  className="profile-card-col"
                >
                  <MembersCards
                    avatar={member.avatar}
                    username={member.username}
                    grade={member.grade}
                    role={member.role}
                    stats1={member.discordmessage}
                    stats1desc="Messages"
                    stats2={member.points}
                    stats2desc="Points"
                    stats3="49"
                    stats3desc="Likes"
                    link={member.website}
                    // ... Ajoutez d'autres propriétés comme gradeDescription, stats1, etc.
                  />
                </Col>
              ))}
            </>
          ) : (
            <>Erreur de connexion</>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default MembersPage;
