import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Button, FormControl } from "react-bootstrap";
import { useUser } from "../AppContext";
import ForgotPassword from "../Modals/ForgotPassword";

const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, user, setUserData } = useUser();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Empêche le comportement par défaut du formulaire (s'il y en a un)
      handleLoginButtonClick(); // Appelle la fonction de gestion de la soumission du formulaire
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  }

  const fetchUserData = async () => {
    try {
      // Effectuer une requête GET pour obtenir les informations de l'utilisateur
      const response = await fetch(`https://api.bounn.fr/users/dashboard`, {
        withCredentials: true,
        credentials: "include",
      });

      if (response.ok) {
        // Si la requête est réussie, mettez à jour le contexte utilisateur avec les informations de l'utilisateur
        const data = await response.json();

        if (JSON.stringify(data.dashboardData) !== JSON.stringify(user)) {
          login(data.dashboardData.user);
          // Set user data in the context
          setUserData(data.dashboardData);
        }
      }
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Problème lors de la connexion");
        return;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de l'utilisateur :",
        error
      );
    }
  };

  const handleLoginButtonClick = async () => {
    try {
      // Effectuer la requête POST pour la connexion (génération du token côté serveur)
      const response = await fetch("https://api.bounn.fr/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // inclue les cookies dans la requête
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Erreur lors de la connexion.");
        return;
      }

      // Si la connexion réussit, récupérer le token depuis la réponse
      const data = await response.json();

      // Mettre à jour le contexte utilisateur avec les informations de l'utilisateur
      login({
        username,
        token: data.token,
      });
      setErrorMessage(""); // Réinitialiser le message d'erreur
      // Rediriger vers la page d'accueil ou une autre page après la connexion
      navigate("/");
      fetchUserData();
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <>
      <Container>
        <div className="login-container">
          <div className="login-card js-login-card">
            <div className="login-card__cnt js-login-cnt">
              <div className="login-card__name">Pseudo :</div>
              <div className="login-card__txt">
                <Row>
                  <Col>
                    <FormControl
                      type="text"
                      placeholder="Nom d'utilisateur"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </Col>
                </Row>
              </div>
              <div className="login-card__name">Mot de passe :</div>
              <div className="login-card__txt">
                <Row>
                  <Col>
                    <FormControl
                      type="password"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </Col>
                </Row>
                <hr></hr>
                <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleForgotPassword}
        >
          Mot de passe oublié
        </span>
              </div>
              <Row className="mx-auto">
                <Button
                  className="fork-btn-inner login-button"
                  onClick={handleLoginButtonClick}
                  onKeyPress={handleKeyPress}
                >
                  LOGIN
                </Button>
              </Row>
              {errorMessage && (
                <Row className="error-message">
                  <Col>{errorMessage}</Col>
                </Row>
              )}
            </div>
          </div>
        </div>
        <ForgotPassword show={showForgotPassword}
        onHide={() => setShowForgotPassword(false)}
        />
      </Container>
    </>
  );
};

export default LoginPage;
