import React, { useState } from "react";
import { useUser } from "../AppContext";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import { AiFillUnlock, AiFillSnippets, AiFillSignal } from "react-icons/ai";
import ModifierProfil from "../Modals/ModifierProfil";
import LinkDiscord from "../Modals/LinkDiscord";


const AccountDashboard = () => {
  const { user } = useUser();

  // GESTION XP

  const calculateLevel = (experience) => {
    if (experience < 50) {
      return { level: 1, maxExperience: 50 };
    } else if (experience < 200) {
      return { level: 2, maxExperience: 200 };
    } else if (experience < 400) {
      return { level: 3, maxExperience: 400 };
    } else if (experience < 700) {
      return { level: 4, maxExperience: 700 };
    } else if (experience < 1000) {
      return { level: 5, maxExperience: 1000 };
    } else {
      return { level: 6, maxExperience: Infinity }; // Pour le niveau maximum
    }
  };

  const currentLevelInfo = calculateLevel(user.experience);
  const { level, maxExperience } = currentLevelInfo;

  const progress = (user.experience / maxExperience) * 100;

  // DATE
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // GRADE
  const getGradeName = (gradeNumber) => {
    switch (gradeNumber) {
      case 1:
        return "Membre";
      case 2:
        return "VIP";
      case 3:
        return "Beta-Testeur";
      case 4:
        return "Modérateur";
      case 5:
        return "Administrateur";
      default:
        return "Inconnu";
    }
  };

  // DISCORD
  const renderDiscordInfo = () => {
    if (user.discord === "none") {
      // Afficher le bouton pour lier Discord
      return (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleLinkDiscord}
        >
          Lier mon Discord
        </span>
      );
    } else {
      // Afficher la valeur Discord
      return user.discord;
    }
  };
  const [showDiscordModal, setShowDiscordModal] = useState(false);
  const handleLinkDiscord = () => {
    setShowDiscordModal(true);
  };

  // BOUTON MODAL MODIFIER PROFIL
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditButtonClick = () => {
    setShowEditModal(true);
  };

  // BOUTON COPIE CODE PARRAIN
  const handleCopyCodeButtonClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(user.codeparrainage)
        .then(() => {
          alert("Code parrain copié dans le presse-papiers !");
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la copie dans le presse-papiers :",
            error
          );
        });
    } else {
      console.error(
        "L'API Clipboard n'est pas prise en charge dans ce navigateur."
      );
    }
  };

  return (
    <Container>
      {user && (
        <>
          {/* // HEADER  */}
          <Row> 
            <Col md={2}>
              <div className="dashboardHeader-img">
                {user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Avatar de l'utilisateur"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/avatars/defaut_avatar.png`}
                    alt="Avatar de l'utilisateur"
                  />
                )}
              </div>
            </Col>
            <Col md={10}>
              <Row>
                <div className="dashboardHeader-card">
                  <Row>
                    <Col md={4} className="dashboardHeader-card__div1">
                      <div className="dashboardHeader-card__cnt">
                        <div className="dashboardHeader-card__name">
                          {user.user}
                        </div>
                        <div className="dashboardHeader-card__txt">
                        {user.role ? (
                            <>
                            {user.role}
                            </>
                          ) : (
                            <>
                            Un <strong>{getGradeName(user.grade)}</strong>{" "}
                            investit !
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={5} className="dashboardHeader-card-inf">
                      <div className="dashboardHeader-card-inf__item">
                        <div className="dashboardHeader-card-inf__title">
                          {user.discordmessage}
                        </div>
                        <div className="dashboardHeader-card-inf__txt">
                          messages
                        </div>
                      </div>

                      <div className="dashboardHeader-card-inf__item">
                        <div className="dashboardHeader-card-inf__title">
                          {user.nbfilleul}
                        </div>
                        <div className="dashboardHeader-card-inf__txt">
                          filleul(s)
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <p className="progress-title">
                        <strong>Niveau actuel : </strong>
                        {level}
                      </p>
                      <div className="progress-text">
                        <strong>Points d'xp : </strong>
                        {user.experience}/{maxExperience}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
          </Row>
          {/* // DATA */}
          <div className="d-flex justify-content-between mt-4">
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>
                  <strong>📃 Utilisateur :</strong>
                </Card.Title>
                <Card.Text style={{ textAlign: "left" }}>
                  <strong>Date d'inscription :</strong>{" "}
                  {formatDate(user.dateInscription)}
                  <br />
                  <strong>Grade : </strong>
                  {getGradeName(user.grade)}
                  <br />
                  <strong>E-mail : </strong>
                  {user.email}
                  <br />
                  <strong>Parrain : </strong>
                  {user.parrain}
                  <br />
                  <strong>Site :</strong> {user.website}
                </Card.Text>
              </Card.Body>
              <Button
                className="fork-btn-inner dashboard-button"
                onClick={handleEditButtonClick}
              >
                MODIFIER <AiFillUnlock />
              </Button>
            </Card>

            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>
                  <strong>👨‍👦 Communauté :</strong>
                </Card.Title>
                <Card.Text style={{ textAlign: "left" }}>
                  Discord : {renderDiscordInfo()}
                  <br />
                  Messages : {user.discordmessage}
                  <br />
                  Temps vocal : {Math.ceil(user.discordvocal / (1000*60*60)*100) / 100} heures
                  <br />
                  <br />
                  <strong>Code parrain : {user.codeparrainage}</strong>
                </Card.Text>
              </Card.Body>
              <Button
                className="fork-btn-inner dashboard-button"
                onClick={handleCopyCodeButtonClick}
              >
                COPIER MON CODE <AiFillSnippets />
              </Button>
            </Card>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>
                  <strong>🥇 Experience</strong>
                </Card.Title>
                <Card.Text style={{ textAlign: "left" }}>
                  Points de succès : {user.points}
                  <br />
                  Quêtes accomplies : {user.quetes}
                  <br />
                  <br />
                  Nombre de visites : {user.visiteweb}
                  <br />
                  Nombre de filleuls : {user.nbfilleul}
                </Card.Text>
              </Card.Body>
              <Button
                className="fork-btn-inner dashboard-button"
                // onClick={handleLoginButtonClick}
              >
                VOIR MES QUETES <AiFillSignal />
              </Button>
            </Card>
          </div>
        </>
      )}
      <ModifierProfil
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
      />
      <LinkDiscord
        show={showDiscordModal}
        onHide={() => setShowDiscordModal(false)}
      />
    </Container>
  );
};

export default AccountDashboard;
